@import './scss/_variables';

.md-paper--1 {
  box-shadow: 0px 0px;
  z-index: 0;
}

.md-list-tile {
  height: min-content;
}
.md-* {
  background-color: transparent;
}


.navigator>a:hover, .navigator>li:hover {
  border: 0px solid $secondary-color;
   background-color: rgba($secondary-color, .3);
}

.md-list-tile--active {
  background: transparent;
}