@import '../../scss/imports';

.description li {

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  margin-bottom: 15px;

  color: #000000;
}

.description h1 {
  text-align: center;
}
.description {
  margin-top: -20px;
  padding-left: inherit;
}

.description ul {
  padding-left: inherit;
}

.r .md-select-field__toggle {
  text-align: left;
}

.r .md-tile-text--primary.md-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  
  color: #000000;
}

.routelist {
  margin-top: 4em;
  text-align: left;
}

li > .md-list-tile {
  height: 2.5rem;
}
