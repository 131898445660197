@import '../../scss/imports';

span[class^="method-"] {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  min-width: 80px;
  padding: 6px 15px;
  text-align: left;
  border-radius: 3px;
  color: white;
  margin-right: 25px;
}

.method-get {background: #61affe}
.method-put {background: #fca130}
.method-post {background: #49cc90}
.method-delete {background: #f93e3e}

.route-path {
  font-size: 14px;
}
